import { getProductDetails } from "../../api/product";
import { QUICKVIEW_CLOSE, QUICKVIEW_OPEN } from "./quickviewActionTypes";

export function quickviewOpenSuccess(product) {
    return {
        type: QUICKVIEW_OPEN,
        product,
    };
}

export function quickviewClose() {
    return {
        type: QUICKVIEW_CLOSE,
    };
}

export function quickviewOpen(product) {
    return (dispatch) => {
        if (product["variant_choices"]) dispatch(quickviewOpenSuccess(product));
        else {
            return new Promise((resolve) => {
                getProductDetails(product.slug).then((res) => {
                    if (res.data) {
                        dispatch(quickviewOpenSuccess(res.data));
                    }

                    resolve();
                });
            });
        }
    };
}
