import { getAuthHeader, appendFormData, paramBuilder } from "../services/utils";
import { syncCart } from "../store/cart";
const axios = require("axios");
// declare an ajax request's cancelToken (globally)
let cartRejectRequest = null;


export async function addToCart(data) {
    var formData = appendFormData(data);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/cart";
    // if (cartRejectRequest) {
    //     cartRejectRequest.cancel();
    // }

    // creates a new token for upcomming ajax (overwrite the previous one)
    // cartRejectRequest = axios.CancelToken.source();

    return new Promise((resolve, reject) => {
        axios
            .post(url, formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
                // cancelToken: cartRejectRequest.token
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            });
    });
}


export async function getMyCart(store) {
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/cart";

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
                store.dispatch(syncCart(data.data));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            });
    });
}

export async function removeCartItem(data) {
    var formData = appendFormData(data);
    let url = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PREFIX + "/cart?_method=DELETE";

    return new Promise((resolve, reject) => {
        axios
            .post(url, formData, {
                headers: getAuthHeader(),
                withCredentials: true,
                timeout: 180000,
            })
            .then((response) => {
                const { data } = response;
                resolve(data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data) {
                        reject({ http_code: error.response.status, data: error.response.data });
                    } else {
                        reject(error.response.data);
                    }
                } else {
                    reject(error.message);
                }
            });
    });
}
