// react
import React from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";

function SocialLinks(props) {
    const { shape, className, socialLinks } = props;

    const classes = classNames(className, "social-links", {
        "social-links--shape--circle": shape === "circle",
        "social-links--shape--rounded": shape === "rounded",
    });

    const items = [
        { type: "facebook", url: socialLinks?.facebook_url, icon: "fab fa-facebook-f" },
        { type: "twitter", url: socialLinks?.twitter_url, icon: "fab fa-twitter" },
        { type: "youtube", url: socialLinks?.youtube_url, icon: "fab fa-youtube" },
        { type: "instagram", url: socialLinks?.instagram_url, icon: "fab fa-instagram" },
        { type: "tiktok", url: socialLinks?.tiktok_url, icon: "fab fa-tiktok" },
        { type: "flickr", url: socialLinks?.flickr_url, icon: "fab fa-flickr" },
    ]
        .filter((item) => item.url && item.url?.length > 1)
        .map((item) => (
            <li key={item.type} className="social-links__item">
                <a
                    className={`social-links__link social-links__link--type--${item.type}`}
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className={item.icon} />
                </a>
            </li>
        ));

    return (
        <div className={classes}>
            <ul className="social-links__list">{items}</ul>
        </div>
    );
}

SocialLinks.propTypes = {
    /**
     * rating value
     */
    shape: PropTypes.oneOf(["circle", "rounded"]),
    className: PropTypes.string,
};
SocialLinks.defaultProps = {
    shape: null,
};

export default SocialLinks;
