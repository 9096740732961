// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// application
import NavPanel from "./NavPanel";
import Search from "./Search";
import Topbar from "./Topbar";
import { LogoSvg } from "../../svg";
import CartIndicator from "./IndicatorCart";
import { connect } from "react-redux";
import IndicatorAccount from "./IndicatorAccount";
import { mobileMenuOpen } from "../../store/mobile-menu";
import { getAccessToken } from "../../api/auth";
import IndicatorNotifcation from "./IndicatorNotifcation";
import { ArrowRoundedDown9x6Svg } from "../../svg";
import AppLink from "../shared/AppLink";
import Menu from "./Menu";
import { url } from "../../services/utils";
import cloneDeep from "lodash/cloneDeep";

const headerMenuList = [
    { id: 1, name: "Home", url: "/" },
    { id: 2, name: "Products", url: url.goProductList(), menu: true },
    { id: 3, name: "About Us", url: "/about-us" },
];

function Header(props) {
    const { openMobileMenu, categories } = props;
    const token = getAccessToken();

    const handleMouseEnter = (event) => {
        const item = event.currentTarget;
    };
    let customCategories;
    if (categories) {
        customCategories = cloneDeep(categories);
        customCategories.unshift({ name: "All Series", slug: "all-series" });
    }

    let bannerSection;

    let submenu;
    let arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
    bannerSection = (
        <div className="site-header__middle container">
            <div className="left">
                {/* <button
                    type="button"
                    className="mobile-header__menu-button desktop"
                    onClick={openMobileMenu}>
                    <div className="bar-style">
                        <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i>
                    </div>
                </button> */}
                <div className="header-menu nav-panel__nav-links nav-links">
                    <ul className="nav-links__list">
                        {headerMenuList.map((item) => {
                            return item.menu ? (
                                <li
                                    key={item.id}
                                    className={"nav-links__item" + (item.menu ? " nav-links__item--with-submenu" : "")}
                                    onMouseEnter={handleMouseEnter}
                                >
                                    <AppLink to={item.url} {...item.props}>
                                        <span>
                                            {item.name}
                                            {customCategories && customCategories.length > 0 ? arrow : null}
                                        </span>
                                    </AppLink>
                                    {customCategories && customCategories.length > 0 ? (
                                        <div className="nav-links__menu">
                                            <Menu items={customCategories} type="category" />
                                        </div>
                                    ) : null}
                                </li>
                            ) : (
                                <li key={item.id}>
                                    <Link to={item.url}>{item.name}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <div className="site-header__logo">
                <Link to="/">
                    <img src="images/logos/logo.png" alt="logo" className="header-logo" />
                </Link>
            </div>

            <div className="right">
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__user">
                    <Link to="/cart" className="btn btn-sm round-btn outline">
                        My Cart
                    </Link>
                    
                    {token ? <IndicatorNotifcation from="header" /> : null}

                    <IndicatorAccount from="header" />
                </div>
            </div>

            {/* <div className="menu-right-top">
                    {token ? null : <Link to="/login">Login / Resgiter</Link>}
                </div> */}
        </div>
    );

    return (
        <div className="site-header">
            {/* <Topbar /> */}
            {bannerSection}
            {/* <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div> */}
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
